import React, {useState} from "react";
import Video from '../../../../dj-v4/src/videos/video-1.mp4';
import {HeroContainer, HeroBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowFoward, ArrowRight} from "./homeElements";
import {Button} from "../Button";

const Hero = () =>{
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

    return(
        <HeroContainer id='home'>
            <HeroBg>
                <video autoPlay loop muted playsInline="true" disablePictureInPicture="true" style={{width: "100%", height: "100%", objectFit: "cover" }}>
                    <source src={Video} type='video/mp4'/>
                </video>
            </HeroBg>
            <HeroContent>
                <HeroH1>DJ KENO</HeroH1>
                <HeroP>Open format DJ based in Austin TX!</HeroP>
                {/*<HeroBtnWrapper>*/}
                {/*    <Button to='signup' onMouseEnter={onHover} onMouseLeave={onHover} primary="true" dark="true" smooth={true} duration={500} spy={true} exact='true' offset={-80}>*/}
                {/*        Get Started {hover ? <ArrowFoward /> : <ArrowRight />}*/}
                {/*    </Button>*/}
                {/*</HeroBtnWrapper>*/}
            </HeroContent>
        </HeroContainer>
    );
};

export default Hero;